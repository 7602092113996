import React, { PureComponent } from 'react';
import {
  SEO,
  LevelHero,
  Inspiration,
  LevelChallenge,
  LevelBenefits,
  LevelObjective
} from 'components';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';
import { Redirect } from '@reach/router';
import star from 'images/Levels/star.svg';
import level8Icon from 'images/Shared/LevelIcons/WhiteStroke/level_08_icon.svg';

class Level8 extends PureComponent {
  state = {
    componentHasMounted: false
  };

  componentDidMount() {
    this.setState({
      componentHasMounted: true
    });
  }

  render() {
    const { data, viewportWidth, user, game, selectedLevel } = this.props;
    const { levelData, ropeKnot } = data;
    const {
      benefits,
      challenge,
      duration,
      levelPageSummary,
      levelVideoCode,
      objective,
      scoring,
      title,
      subTitle
    } = levelData.childMarkdownRemark.frontmatter;
    let { tools } = levelData.childMarkdownRemark.frontmatter;
    const { componentHasMounted } = this.state;
    if (game && game.uniqueDocs) {
      game.uniqueDocs.forEach((uniqueDocs) => {
        if (uniqueDocs.level === selectedLevel) {
          tools = {
            list: tools.list,
            link: uniqueDocs.link
          };
        }
      });
    }
    return user ? (
      <Wrapper>
        <SEO pageSpecificTitle="Level 8" pageSpecificDescription={levelPageSummary} />
        <LevelHero
          level={8}
          title={title}
          subTitle={subTitle}
          levelPageSummary={levelPageSummary}
          levelIcon={level8Icon}
          levelVideoCode={levelVideoCode}
        />
        <LevelObjective objective={objective} />
        <LevelChallenge
          challenge={challenge}
          duration={duration}
          tools={tools}
        />
        <Overview>
          {/* <div>
            <LightGreenBox>
              <img src={star} alt="star" />
            </LightGreenBox>
            <p>
              <span>Notes</span> — Here we are looking for the strategy, not the creative ideas that
              will deliver on the strategy... that comes next!
            </p>
          </div> */}
          <p
            style={{
              fontSize: '1.5rem',
              lineHeight: '1.333em',
              maxWidth: '926px'
            }}>
            <b>We suggest your team’s activities are structured as follows:</b>
            <br />
            <br />
          </p>
          <div>
            <GreenBox>1.</GreenBox>
            <p>
              <span>Reading Phase</span> — Review the chosen company strategy document together with
              previous campaign materials and any updated materials that may be coming your way.
              <span>Allow 2 hours</span>
              <br />
              <div>
                <LightGreenBox>
                  <img src={star} alt="star" />
                </LightGreenBox>
                <p>
                  <span>Note 1</span> — Creative thought is an individual pursuit. Teams are there
                  to round out the initial idea, but at its root it will come from one individual,
                  any one, so listen well!
                </p>
              </div>
              <br />
              <br />
              <div>
                <LightGreenBox>
                  <img src={star} alt="star" />
                </LightGreenBox>
                <p>
                  <span>Note 2</span> — We are all creative. Not to be confused with visual art,
                  creativity is innate, it is an evolutionary survival skill. The visual art people
                  may be able to make it look nicer that’s all!
                </p>
              </div>
            </p>
          </div>
          <div>
            <GreenBox>2.</GreenBox>
            <p>
              <span>Ideation Workshop 1</span> — A team workshop where we regroup around the key
              points of the chosen strategy. This essentially becomes the creative brief that must
              be addressed, but also references the BRAAD doc. Set out as concise a list as possible
              of the core ideas that the creative must bring together. We are then looking for at
              least 3 creative concepts.
              <br />
              <br />
              Here is a guide:
              <br />
              <br />
              <div>
                <BeigeBox>A</BeigeBox>
                <p>
                  The ‘Ronseal’ approach. It does exactly what it says on the tin’. This is a no-
                  nonsense, factually based creative approach.
                </p>
              </div>
              <br />
              <br />
              <div>
                <BeigeBox>B</BeigeBox>
                <p>
                  The ‘Evolution’ approach. Taking the best bits of the current campaign, we
                  rejuvenate, refresh and modernise their impact for the new creative.
                </p>
              </div>
              <br />
              <br />
              <div>
                <BeigeBox>C</BeigeBox>
                <p>
                  The ‘Revolution’ or ‘game changer’ approach. A totally new approach to the
                  creative way our campaign will be marketed.
                  <br /><br />
                  <span style={{ fontWeight: 700 }}>Note on C:</span> Revolution is a vital part of the ideation process. We must get out there to really push the boundaries. We can always come back in again, or take elements of the new approach and apply some of them.
                </p>
              </div>
              <span>
                Allow 1 hour prep and 1.5 hours maximum to go through<br />the framing of initial ideas
              </span>
              <span>Allow 1.5 hours to draft the notes</span>
            </p>
          </div>
          <div>
            <GreenBox>3.</GreenBox>
            <p>
              <span>Ideation Workshop 2</span> — Circulate the notes from session 1 asap and leave at least 1 full day for each individual to play around with ideas.<br />We suggest the team captains gather all ideas from each member before the workshop and group them together as much as possible.<br />At the workshop go through each idea first to see if any fit into any of the 3 genres above. Pick the team’s top three and work through those against the strategy criteria and the media preferred.
              <br />
              <span>Allow 1 hour for the preparation</span>
              <span>Allow 1.5 hours for the workshop</span>
              <span>Allow 2 hours to draft the design brief</span>
            </p>
          </div>
          <div>
            <GreenBox>4.</GreenBox>
            <p>
              <span>Design Brief</span> — Using the BRADD structure from level 2, liaise with your designer to get a visual presentation of your concept created. This will be what you present on the ‘Dragon’s Den’ pitch.
              <span>You have 4 hours of design time per team</span>
            </p>
          </div>
          <div>
            <GreenBox>5.</GreenBox>
            <p>
              <span>Prepare and rehearse your pitch:</span>
              <span>You will have 10 minutes to present on the day</span>
            </p>
          </div>
        </Overview>
        <InnerWrapper>
          <LevelBenefits
            benefits={benefits}
            level={selectedLevel}
            objective={objective}
            viewportWidth={viewportWidth}
            scoring={scoring}
          />
        </InnerWrapper>
        <Inspiration
          img={ropeKnot}
          author="Robert McKee, 'Storynomics'"
          quote="Creativity: Finding the new connection between 2 familiar things"
          quoteLargeFontSize="3.825rem"
          maxWidth="39.625em"
          textWrapperTop="6em"
          viewportWidth={viewportWidth}
        />
      </Wrapper>
    ) : (
      componentHasMounted && <Redirect to="/" noThrow />
    );
  }
}

const Wrapper = styled.section``;

const InnerWrapper = styled.div`
  max-width: 90em;
  margin: 0 9.1%;
`;

const Overview = styled.div`
  background-color: rgba(239, 237, 228, 0.3);
  padding: 0 9.027777777777777% 2.625rem;
  display: flex;
  flex-direction: column;

  > div {
    align-items: flex-start;
    display: inline-flex;
    font-size: 1.5rem;
    width: 100%;
    margin-bottom: 2.75em;

    > p {
      margin-left: 0.833em;
      position: relative;
      top: 0.325em;
      font-weight: 400;
      line-height: 1.333em;
      max-width: 874px;

      > span {
        font-weight: 700;
      }

      > span:nth-of-type(2) {
        margin-top: 1em;
      }

      > span:not(:first-of-type) {
        display: block;
        color: var(--cx-dark-green);
      }

      > div {
        align-items: flex-start;
        display: inline-flex;
        width: 100%;

        p {
          margin-left: 0.833em;

          span {
            font-weight: 700;
          }
        }
      }
    }

    ul {
      margin-top: 0.75em;
      line-height: 1.333em;

      li {
        display: flex;
        margin-bottom: 0.75em;

        span {
          margin-right: 1.5em;
        }
      }
    }
  }
`;

const GreenBox = styled.span`
  align-items: center;
  background-color: var(--cx-dark-green);
  border-radius: 0.125em;
  bottom: 0.02em;
  color: #fff;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  font-weight: 600;
  height: 1.917em;
  justify-content: center;
  position: relative;
  width: 1.917em;
`;

const BeigeBox = styled.span`
  align-items: center;
  background-color: var(--cx-dark-beige);
  border-radius: 0.125em;
  bottom: 0.02em;
  color: #fff;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  font-weight: 600;
  height: 1.917em;
  justify-content: center;
  position: relative;
  width: 1.917em;
`;

const LightGreenBox = styled.span`
  align-items: center;
  background-color: var(--cx-light-green);
  border-radius: 0.125em;
  bottom: 0.02em;
  color: #fff;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  font-weight: 600;
  height: 1.917em;
  justify-content: center;
  position: relative;
  width: 1.917em;
`;

export const Level8Query = graphql`
  {
    levelData: file(sourceInstanceName: { eq: "levelData" }, relativePath: { eq: "level_08.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          subTitle
          levelPageSummary
          levelVideoCode
          challenge {
            heading
            detail
          }
          objective
          benefits {
            forPlayers
            forTheCampaign
          }
          duration {
            time
            detail
          }
          scoring {
            challenge {
              points
              detail
            }
            teamGame {
              points
              detail
            }
          }
          tools {
            list
            link
          }
        }
      }
    }
    ropeKnot: file(relativePath: { eq: "Shared/Inspiration/rope_knot.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 634, maxHeight: 620) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default Level8;
